import { MODAL_OPTIONS } from '@/app/modals/constants';
import { NAME } from '@/app/navigation/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { Squares2X2Icon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { IconWrapper } from '@/app/navigation/components/New/WorkspaceSelector/IconWrapper';
import { WorkspaceIcon } from '@/app/navigation/components/New/WorkspaceSelector/WorkspaceIcon';
import { WorkspaceSelectorDropdown } from '@/app/navigation/components/New/WorkspaceSelector/WorkspaceSelectorDropdown';
import { getWorkspaceBackgroundColor } from '@/app/navigation/helpers';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { getIsPreview, navigateToAddNewWorkspacePage } from '@/app/workspaces/models/workspaces';
import { WorkspaceSetting } from '@/app/workspaces/types';
import { useDefaultWorkspaceEnabled } from '@/core/loli-feature-flags/features/defaultWorkspace';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import { cn } from '@/utils/cn';
import { getIsWorkspaceEnv } from '@/utils/environments';

import type { WorkspaceResource } from '@/app/workspaces/types';

export const WorkspaceSelector = () => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { pathname } = useRouter();

    const { activeWorkspace, sortedWorkspaces, workspaceLimit } = useWorkspaces();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { canCreate, canUpdate } = useUserCapabilities(CapabilitiesTarget.Workspaces);
    const isWorkspaceEnv = getIsWorkspaceEnv();
    const isPreview = useAppSelector(getIsPreview);

    const showAddNewWorkspace = canCreate;
    // Workspace quantity check is moved to WorkspaceSetup
    const canAddNewWorkspace = showAddNewWorkspace;

    const isDefaultWorkspace = !!activeWorkspace?.attributes?.default;
    const isCustomWorkspace = !isDefaultWorkspace;
    const isDefaultWorkspaceEnabled = useDefaultWorkspaceEnabled();
    const showWorkspaceActions =
        (isCustomWorkspace || isWorkspaceEnv || isDefaultWorkspaceEnabled) && canUpdate;

    const disableDropdown = isPreview;

    const isAllWorkspacesViewOpen = pathname === '/workspaces';

    const workspacesToShow = useMemo(() => {
        // On the workspace preview page we only show the active workspace
        if (isPreview) {
            return [];
        }

        // Show N many workspaces based on the workspace limit
        return sortedWorkspaces.slice(0, workspaceLimit);
    }, [isPreview, sortedWorkspaces, workspaceLimit]);

    const sharedTrackingData = useMemo(() => {
        return {
            currentWorkspace: {
                default: isDefaultWorkspace,
                custom: isCustomWorkspace,
            },
            whiteLabelView: isWorkspaceEnv,
        };
    }, [isDefaultWorkspace, isCustomWorkspace, isWorkspaceEnv]);

    const handleOnOpenChange = useCallback(
        (open: boolean) => {
            if (!disableDropdown) {
                setDropdownOpen(open);
            }

            const trackingData = { ...sharedTrackingData };

            if (open) {
                track(TRACKING_EVENTS.navigation.workspaceSelector.opened, trackingData);
            } else {
                track(TRACKING_EVENTS.navigation.workspaceSelector.closed, trackingData);
            }
        },
        [disableDropdown, sharedTrackingData],
    );

    const handleManageMembersClick = useCallback(() => {
        dispatch(
            showModal(
                Modals.WORKSPACE_SETTINGS,
                { activeTab: WorkspaceSetting.People },
                MODAL_OPTIONS[Modals.WORKSPACE_SETTINGS],
            ),
        );

        track(
            TRACKING_EVENTS.navigation.workspaceSelector.dropdown.manageMembersClicked,
            sharedTrackingData,
        );
    }, [dispatch, sharedTrackingData]);

    const handleWorkspaceSettingsClick = useCallback(() => {
        dispatch(
            showModal(Modals.WORKSPACE_SETTINGS, {}, MODAL_OPTIONS[Modals.WORKSPACE_SETTINGS]),
        );

        track(
            TRACKING_EVENTS.navigation.workspaceSelector.dropdown.workspaceSettingsClicked,
            sharedTrackingData,
        );
    }, [dispatch, sharedTrackingData]);

    const handleWorkspaceSelected = useCallback(
        (workspace: WorkspaceResource) => {
            track(TRACKING_EVENTS.navigation.workspaceSelector.dropdown.workspaceSelected, {
                ...sharedTrackingData,
                selectedWorkspace: {
                    id: workspace.id,
                    default: !!workspace?.attributes?.default,
                    custom: !workspace?.attributes?.default,
                },
            });
        },
        [sharedTrackingData],
    );

    const handleAddNewWorkspace = useCallback(() => {
        // We have one flag for workspace creation:
        // - showAddNewWorkspaceButton is used to hide/show the "New Workspace" button -> relevant
        //   for CRM users, as they shouldn't see the button
        // Whether a user can actually create a workspace check is moved to WorkspaceSetup
        dispatch(navigateToAddNewWorkspacePage(canAddNewWorkspace));

        track(
            TRACKING_EVENTS.navigation.workspaceSelector.dropdown.addWorkspaceClicked,
            sharedTrackingData,
        );
    }, [dispatch, canAddNewWorkspace, sharedTrackingData]);

    const handleAllWorkspacesViewClicked = useCallback(() => {
        track(
            TRACKING_EVENTS.navigation.workspaceSelector.dropdown.allWorkspacesClicked,
            sharedTrackingData,
        );
    }, [sharedTrackingData]);

    if (!activeWorkspace) {
        return <></>;
    }

    return (
        <DropdownMenu.Root onOpenChange={handleOnOpenChange}>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(
                        'flex items-center rounded-lg p-1 text-sm font-semibold text-gray-800 outline-none transition hover:bg-gray-100',
                        {
                            'bg-gray-100': dropdownOpen,
                            'cursor-pointer': !disableDropdown,
                        },
                    )}
                >
                    {isAllWorkspacesViewOpen ? (
                        <IconWrapper>
                            <Squares2X2Icon className="size-4" />
                        </IconWrapper>
                    ) : (
                        <WorkspaceIcon
                            name={activeWorkspace?.attributes?.name}
                            logoSrc={activeWorkspace?.attributes?.logo}
                            bgColor={getWorkspaceBackgroundColor(activeWorkspace?.attributes?.name)}
                        />
                    )}

                    <span className="ml-4 max-w-full select-none truncate whitespace-pre max-xl:max-w-56 max-lg:hidden">
                        {isAllWorkspacesViewOpen
                            ? t('all-workspaces')
                            : activeWorkspace?.attributes?.name}
                    </span>

                    {!disableDropdown && (
                        <ChevronDownIcon
                            className={cn('mx-1 size-5 transition-transform max-lg:hidden', {
                                'rotate-180': dropdownOpen,
                            })}
                        />
                    )}
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content
                asChild
                className="origin-top-left data-[state=closed]:animate-dropdown-out data-[state=open]:animate-dropdown-in lg:origin-top"
                sideOffset={6}
                side="bottom"
                align="start"
            >
                <div className="max-h-[75vh] min-w-[296px] origin-top-left overflow-y-auto rounded-xl border bg-white shadow-dropdown lg:origin-top">
                    <WorkspaceSelectorDropdown
                        workspaces={workspacesToShow}
                        activeWorkspace={activeWorkspace}
                        showActions={showWorkspaceActions}
                        manageMembersClicked={handleManageMembersClick}
                        workspaceSettingsClicked={handleWorkspaceSettingsClick}
                        showAddNewWorkspaceButton={showAddNewWorkspace}
                        onWorkspaceSelected={handleWorkspaceSelected}
                        onAddNewWorkspace={handleAddNewWorkspace}
                        onAllWorkspacesViewClicked={handleAllWorkspacesViewClicked}
                        isAllWorkspacesViewOpen={isAllWorkspacesViewOpen}
                    />
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
};
