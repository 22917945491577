import { NAME } from '@/app/navigation/constants';

import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { Button } from '@/ui/components/Button';

export interface Props {
    onClick: () => void;
}

export const InviteButton = ({ onClick }: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <Button
            onClick={onClick}
            size="small"
            className="h-10 rounded-lg py-2 text-sm font-medium text-gray-500 hover:text-gray-800"
        >
            <UserPlusIcon className="size-5" />

            <span className="ml-2 max-lg:hidden">{t('invite')}</span>
        </Button>
    );
};
